import React from "react"
import TinySlider from "tiny-slider-react";
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

import "../styles/technologies.scss"
import TechHeader from "../images/header-za-tehnologiju.png";
import TechInnerBg from "../images/header_transparentni.png";

const settingsFirst = {
  lazyload: false,
  nav: false,
  mouseDrag: true,
    axis: "vertical",
    autoplay: true,
    autoplayDirection: "forward",
    autoplayButton: false,
    autoplayButtonOutput: false,
    controls: false,
    speed: 500,
    autoplayTimeout: 1000,
    gutter: 0,
    center: true,
    animateIn: "tns-fadeIn",
    animateOut: "tns-fadeOut"
};

const settingsSecond = {
  lazyload: false,
  nav: false,
  mouseDrag: true,
    axis: "vertical",
    autoplay: true,
    autoplayDirection: "backward",
    autoplayButton: false,
    autoplayButtonOutput: false,
    controls: false,
    speed: 500,
    autoplayTimeout: 1000,
    gutter: 0,
    center: true,
    animateIn: "tns-fadeIn",
    animateOut: "tns-fadeOut"
};

const linkStyle = {
    pointerEvents: 'none',
    cursor: 'default'
}

const Technology = (props) => (
  <Layout>
    <SEO title="Technologies" keywords={[`technologies`]}
    description="The company specializes in custom software and website development using
open source technologies and products that are built on top of it. When we work on existing
codebases, we use the languages, frameworks, and technologies that those codebases are
already built in-take a look."
    />
    <div className="technology-main" style={{ backgroundImage: `url(${TechHeader})` }}>
      <div className="container">
          <div className="">
              <div className="row d-flex justify-content-center">
              <div className="col-10">
                <div className="technology-main--heading pb-5 text-md-left text-center">
                    <h1>Technologies</h1>
                    <p className="text-justify">Our strong passion for modern technology trends and best practices drive us to
                        develop top-notch solutions that are perfectly tailored to customer IT and business needs.
                        We rely on open source technologies, which provided flexibility to customize solutions at
                        reasonable costs.</p>
                        <p className="text-justify">We use Agile development methodology in complex projects,
                            who allows us to save time, speed up the implementation process and improve the quality of
                            a product's usability.</p>
                </div>
            </div>
            </div>
            
            <div className="technology-main--sub-heading mb-md-5 mt-md-5">
                <h3>We love to work with these technologies</h3>
            </div>
              {/*First slider line*/}
              <div className="row first-slide slider-section justify-content-center">
                  <div className="col-5">
                      <div className="slider left">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsFirst}>
                              <div className="single-slide">
                                  <p>PHP</p>
                              </div>
                              <div className="single-slide">
                                  <p>SQL</p>
                              </div>
                              <div className="single-slide">
                                  <p>PHP</p>
                              </div>
                              <div className="single-slide">
                                  <p>SQL</p>
                              </div>
                              <div className="single-slide">
                                  <p>PHP</p>
                              </div>
                              <div className="single-slide">
                                  <p>SQL</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
                  <div className="col-1 pl-0 pr-0"><div className="middle"><p>using</p></div></div>
                  <div className="col-5">
                      <div className="slider right">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsSecond}>
                              <div className="single-slide">
                                  <p>Wordpress</p>
                              </div>
                              <div className="single-slide">
                                  <p>MySQL</p>
                              </div>
                              <div className="single-slide">
                                  <p>Laravel</p>
                              </div>
                              <div className="single-slide">
                                  <p>Oracle</p>
                              </div>
                              <div className="single-slide">
                                  <p>Symfony</p>
                              </div>
                              <div className="single-slide">
                                  <p>MySQL</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
              </div>
              {/*Second slider line*/}
              <div className="row slider-section justify-content-center">
                  <div className="col-5">
                      <div className="slider left">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsFirst}>
                              <div className="single-slide">
                                  <p>JavaScript</p>
                              </div>
                              <div className="single-slide">
                                  <p>JavaScript</p>
                              </div>
                              <div className="single-slide">
                                  <p>React</p>
                              </div>
                              <div className="single-slide">
                                  <p>JavaScript</p>
                              </div>
                              <div className="single-slide">
                                  <p>JavaScript</p>
                              </div>
                              <div className="single-slide">
                                  <p>React</p>
                              </div>
                              <div className="single-slide">
                                  <p>JavaScript</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
                  <div className="col-1 pl-0 pr-0"><div className="middle"><p>using</p></div></div>
                  <div className="col-5">
                      <div className="slider right">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsSecond}>
                              <div className="single-slide">
                                  <p>Vue.js</p>
                              </div>
                              <div className="single-slide">
                                  <p>jQuery</p>
                              </div>
                              <div className="single-slide">
                                  <p>Gatsby</p>
                              </div>
                              <div className="single-slide">
                                  <p>ReactNative</p>
                              </div>
                              <div className="single-slide">
                                  <p>Angular</p>
                              </div>
                              <div className="single-slide">
                                  <p>Material UI</p>
                              </div>
                              <div className="single-slide">
                                  <p>Nuxt.js</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
              </div>
              {/*Third slider line*/}
              <div className="row slider-section justify-content-center">
                  <div className="col-5">
                      <div className="slider left">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsFirst}>
                              <div className="single-slide">
                                  <p>NodeJS</p>
                              </div>
                              <div className="single-slide">
                                  <p>HTML</p>
                              </div>
                              <div className="single-slide">
                                  <p>NodeJS</p>
                              </div>
                              <div className="single-slide">
                                  <p>HTML</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
                  <div className="col-1 pl-0 pr-0"><div className="middle"><p>using</p></div></div>
                  <div className="col-5">
                      <div className="slider right">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsSecond}>
                              <div className="single-slide">
                                  <p>Express</p>
                              </div>
                              <div className="single-slide">
                                  <p>Bootstrap</p>
                              </div>
                              <div className="single-slide">
                                  <p>Express</p>
                              </div>
                              <div className="single-slide">
                                  <p>Foundation</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
              </div>
              {/*Fourth slider line*/}
              <div className="row slider-section justify-content-center">
                  <div className="col-5">
                      <div className="slider left">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsFirst}>
                              <div className="single-slide">
                              <p>Java</p>
                          </div>
                              <div className="single-slide">
                                  <p>Perl</p>
                              </div>
                              <div className="single-slide">
                                  <p>Java</p>
                              </div>
                              <div className="single-slide">
                                  <p>Perl</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
                  <div className="col-1 pl-0 pr-0"><div className="middle"><p>using</p></div></div>
                  <div className="col-5">
                      <div className="slider right">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsSecond}>
                              <div className="single-slide">
                                  <p>Spring</p>
                              </div>
                              <div className="single-slide">
                                  <p>Mouse</p>
                              </div>
                              <div className="single-slide">
                                  <p>Android</p>
                              </div>
                              <div className="single-slide">
                                  <p>Mojolicious</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
              </div>
              {/*Fifth slider line*/}
              <div className="row slider-section justify-content-center">
                  <div className="col-5">
                      <div className="slider left">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsFirst}>
                              <div className="single-slide">
                                  <p>SASS</p>
                              </div>
                              <div className="single-slide">
                                  <p>C#</p>
                              </div>
                              <div className="single-slide">
                                  <p>SASS</p>
                              </div>
                              <div className="single-slide">
                                  <p>C#</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
                  <div className="col-1 pl-0 pr-0"><div className="middle"><p>using</p></div></div>
                  <div className="col-5">
                      <div className="slider right">
                          {typeof window !== 'undefined' &&
                          <TinySlider settings={settingsSecond}>
                              <div className="single-slide">
                                  <p>Bootstrap</p>
                              </div>
                              <div className="single-slide">
                                  <p>Net Core</p>
                              </div>
                              <div className="single-slide">
                                  <p>Foundation</p>
                              </div>
                              <div className="single-slide">
                                  <p>Net Core</p>
                              </div>
                          </TinySlider>
                          }
                      </div>
                  </div>
              </div>
          </div>
      </div>
        <div className="row justify-content-center inner_bg align-items-end" style={{ backgroundImage: `url(${TechInnerBg})` }}>
            <div className="col-md-8 inner_bg--inner">
                <div className="technology-main--sub-heading-second">
                    <h3 className="mb-5 mt-5">Companies that we are working with</h3>
                </div>
                <div className="row companies">
                    <div className="col-lg-4 col-sm-6 col-12">
                        <a href="https://www.nis.eu/" target="_blank" rel="nofollow" > 
                            <Img fluid={props.data.nis_logo.childImageSharp.fluid} />
                        </a>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <a href="https://www.autoscout24.com/" target="_blank" rel="nofollow"> 
                            <Img fluid={props.data.as_logo.childImageSharp.fluid} />   
                        </a>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <a href="https://otrs.com/" target="_blank" rel="nofollow"> 
                            <Img fluid={props.data.otrs_logo.childImageSharp.fluid} />                            
                        </a>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <a href="http://accelerator.biosense.rs/" target="_blank" rel="nofollow"> 
                            <Img fluid={props.data.bio_logo.childImageSharp.fluid} />  
                        </a>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <a href="http://www.gooogle.com" target="_blank" rel="nofollow" style={linkStyle}> 
                            <Img fluid={props.data.exit_logo.childImageSharp.fluid} />                              
                        </a>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <a href="http://www.rapidinvest.rs/" target="_blank" rel="nofollow"> 
                            <Img fluid={props.data.rapid_logo.childImageSharp.fluid} />                              
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </Layout>
)

export default Technology

export const pageQuery = graphql`
  query {
    as_logo: file(relativePath: { eq: "autoscaut.png" }) {
      ...fluidImage
    }
    nis_logo: file(relativePath: { eq: "nis.png"}) {
    ...fluidImage
    }
    otrs_logo: file(relativePath: { eq: "otrs.png"}) {
    ...fluidImage
    }
    exit_logo: file(relativePath: { eq: "exit.png"}) {
    ...fluidImage
    }
    rapid_logo: file(relativePath: { eq: "rapidinvest.png"}) {
    ...fluidImage
    }
    bio_logo: file(relativePath: { eq: "biosense.png"}) {
    ...fluidImage
    }
  }
`
